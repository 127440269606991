<template lang="pug">
div(class="d-flex flex-column h-100 py-0")
  HeaderGrid(title="Paramètres" :uid="uid" @onFilterTextBoxChanged="onFilterTextBoxChanged" @clickAction="clickAction" :addButton="addButton" :searchButton="searchButton" :selectedRows="selectedRows" :searchPlaceholder="searchPlaceholder" :indexSearch="indexSearch")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingModuleParametersList')
        .loading-bg-inner
            .loader
                .outer
                .middle
                .inner
        .mt-5
            br
            br
            br
            | Chargement de la liste des paramètres de module...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :key="gridKey" :commandClick='commandClick' class='col-12' style="padding: 0px!important; max-height: calc(100% - 62px) !important" :gridData="moduleParametersList" :name="name" :headerData="headerData")
  b-modal(id="modal-edit-add-parameter" cancel-title="Annuler" ok-title="Enregistrer" centered @ok.prevent="submitForm" :title="selectedParameter && selectedParameter.id ? 'Modifier : '+ selectedParameter.label : 'Ajouter un paramètre'" size="lg")
    div
      validation-observer(ref='formModuleParameter')
        form.d-flex.flex-column(@submit.prevent="submitForm")
          .mb-1
            validation-provider(#default='{ errors }' name='Module' rules='required')
              b-form-group(label='Module' label-for='Module')
                ejs-dropdownlist( id="moduleId" name="module" placeholder="Selectionnez un module"  popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="modulesList" :fields="{ text: 'label', value: 'id' }" v-model="selectedParameter.moduleId" :showClearButton="false")
              small.text-danger {{ errors[0] }}
          validation-provider(#default='{ errors }' name='Key' rules='required')
            label(for="key") Clé (unique)
            b-form-input(:disabled="selectedParameter.id > 0" class="e-input" type="text" id="key" v-model="selectedParameter.key" required)
            small.text-danger {{ errors[0] }}
          validation-provider(#default='{ errors }' name='Label' rules='required')
            label.mt-1(for="label") Libellé
            b-form-input(class="e-input" type="text" id="label" v-model="selectedParameter.label" required)
            small.text-danger {{ errors[0] }}
          validation-provider(#default='{ errors }' name='Value')
            label.mt-1(for="label") Valeur
            b-form-textarea.code-editor(id="jsonInput" v-model="selectedParameterJson" rows="25" placeholder="Entrez le JSON")

</template>
<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import SyncGrid from "@/components/global/grid/Grid.vue";
import Vue from "vue";
import {
  GridPlugin,
  Page,
  Toolbar,
  Edit,
  CommandColumn,
} from "@syncfusion/ej2-vue-grids";
import { closest } from "@syncfusion/ej2-base";
Vue.use(GridPlugin);

import { mapGetters, mapActions } from "vuex";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  data() {
    return {
      indexSearch: 0,
      position: "Right",
      type: "Push",
      name: "moduleParameters",
      uid: "moduleParameters",
      archive: false,
      headerData: [],
      selectedRows: [],
      gridKey: 1,
      selectedParameter: null,
      selectedParameterJson: null,
      addButton: true,
      searchButton: true,
      searchPlaceholder: "Rechercher un paramètre de module",
      commands: [
        {
          type: "editValue",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
          },
        },
        {
          type: "deleteParameter",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
      ],
    };
  },
  provide: {
    grid: [Page, Edit, Toolbar, CommandColumn],
  },
  async created() {
    await this.getModuleParameters({}).then(() => {
      this.indexSearch++;
    });
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 15,
        maxWidth: 15,
        visible: true,
      },
      {
        type: "number",
        field: "moduleId",
        headerText: "Module",
        template: "moduleTemplate",
        width: 60,
        maxWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        unavailableExport: true,
        allowGrouping: false,
      },
      {
        type: "string",
        field: "key",
        headerText: "Clé",
        width: 60,
        maxWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        unavailableExport: true,
        allowGrouping: false,
      },
      {
        type: "string",
        field: "label",
        headerText: "Libellé",
        width: 150,
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: false,
        showInColumnChooser: true,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "string",
        field: "value",
        headerText: "Valeur",
        width: 150,
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "commands",
        commands: this.commands,
        headerText: "Actions",
        width: 120,
      },
    ];
  },
  provide: {
    grid: [CommandColumn],
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    civilityTranslate,
    ...mapActions([
      "getModuleParameters",
      "createModuleParameter",
      "updateModuleParameter",
      "createOrUpdateModuleParameter",
      "deleteModuleParameters",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    clickAction() {
      this.selectedParameter = {
        id: 0,
        moduleId: 1,
        key: "",
        label: "",
        value: "",
      };
      this.selectedParameterJson = "";
      this.editValue(this.selectedParameter);
    },
    commandClick(args) {
      this.selectedParameter = args.rowData;
      if (args.commandColumn.type === "editValue") {
        this.editValue(args.rowData);
      } else if (args.commandColumn.type === "deleteParameter") {
        this.deleteParameter(args.rowData);
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    deleteParameter(rowData) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer ce paramètre ?", {
          title: "Suppression",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteModuleParameters({
              moduleParameterIds: [rowData.id],
            });
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
    editValue(rowData) {
      const originalJson = rowData.value;
      let isValidJson = false;
      try {
        JSON.parse(originalJson);
        isValidJson = true;
      } catch (error) {
        isValidJson = false;
      }

      if (isValidJson) {
        this.selectedParameterJson = JSON.stringify(
          JSON.parse(originalJson),
          null,
          2
        );
      } else {
        this.selectedParameterJson = originalJson;
      }
      this.$bvModal.show("modal-edit-add-parameter");
    },
    submitForm() {
      this.$refs.formModuleParameter.validate().then((success) => {
        if (success) {
          // Form is valid, proceed with your logic
          this.EditAddModuleParameter(this.selectedParameterJson);
        }
      });
    },
    EditAddModuleParameter(json) {
      const messageVariant =
        this.selectedParameter && this.selectedParameter.id
          ? "'" + this.selectedParameter.label + "' mis à jour"
          : "'" + this.selectedParameter.label + "' créé";
      let requete = "";
      if (this.selectedParameter && this.selectedParameter.id) {
        requete = this.updateModuleParameter({
          moduleParameter: {
            id: this.selectedParameter.id,
            moduleId: this.selectedParameter.moduleId,
            label: this.selectedParameter.label,
            key: this.selectedParameter.key,
            value: json,
          },
        });
      } else {
        requete = this.createModuleParameter({
          moduleParameter: {
            id: 0,
            moduleId: this.selectedParameter.moduleId,
            label: this.selectedParameter.label,
            key: this.selectedParameter.key,
            value: json,
          },
        });
      }
      requete
        .then(() => {
          this.$bvToast.toast(`Paramètre ${messageVariant}.`, {
            title: "Succès",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("modal-edit-add-parameter");
        })
        .catch(() => {
          this.$bvToast.toast("Une erreur est survenue.", {
            title: "Erreur",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingModuleParametersList",
      "moduleParametersList",
      "modulesList",
    ]),
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    SyncGrid,
    HeaderGrid,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }

  .code-editor {
    font-family: monospace;
    background-color: rgb(48, 48, 48);
    color: whitesmoke;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}
</style>
